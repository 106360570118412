import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { BaseButton, Container, SideBox } from "../components/styledComponents"
import ContactForm from "../components/contactForm"
import ArrowMore from "../components/arrowMore"
import {
  BigMobileFont,
  MediumMobileFont,
  SmallMobileFont,
} from "../components/typography"
import BaseImg from "../components/baseImg"
import ServicesSection from "../components/servicesSection"
import CenterBorderedHeader from "../components/centerBorderedHeader"
import SidesBorderedBox from "../components/sidesBorderedBox"
import ListSection from "../components/listSection"
import AgenciesSlider from "../components/sliders/agenciesSlider"
import ClientsSlider from "../components/sliders/clientsSlider"
import PortfolioSlider from "../components/sliders/portfolioSlider"
import PackSection from "../components/packSection"
import { Helmet } from "react-helmet"

const TwoColumns = styled.div`
  display: flex;
  align-items: center;
  margin: ${(props) => props.desktopMargin};
  @media (max-width: ${(props) => props.breakpoint}) {
    margin: 0;
    flex-direction: column;
  }

  .gatsby-image-wrapper {
    height: auto !important;
  }
`

const ContactInfo = styled.div`
  padding: 32px;

  p {
    margin-bottom: 0.5rem;
  }
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`
const ColumnImage = styled(BaseImg)`
  width: 47%;
  margin-bottom: 0;
  @media (max-width: 768px) {
    width: 80%;
  }
`

const EdgeImg = styled(BaseImg)`
  position: relative;
  z-index: -1;
  width: 90vw;
  margin: -17% -25vw -5% 2.5%;
  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`
const ArrowMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0 50px;
  @media (max-width: 768px) {
    margin: 0;
  }
`
const StyledSideBox = styled(SideBox)`
  margin-left: 0;
  padding-left: 0;
  width: 100%;
  margin-bottom: 36px;
  @media (max-width: 768px) {
    padding: 32px 16px;
  }
`
const SideBoxWrapper = styled.div`
  width: 66%;
  min-width: 450px;
  @media (max-width: 768px) {
    width: 100%;
    min-width: auto;
  }
`
const HeroText = styled.p`
  font-size: 1.125rem;
  line-height: 1.6;
  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.5;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 60px 0 40px;

  a:first-of-type {
    background: #fff;
    box-shadow: 0 3px 6px #00000029;
    font-weight: 600;
    color: ${(props) => props.theme.secondary};
    border: 1px solid ${(props) => props.theme.secondary};
    transition: color 0.1s ease-in-out, background 0.2s ease-in-out;

    :focus-within {
      border-style: dashed;
      background: #fff;
      color: ${(props) => props.theme.secondary};
      outline: none;
    }

    :hover {
      color: #fff;
      background: ${(props) => props.theme.secondary};
    }

    @media (max-width: 1315px) {
      margin-top: 0.5rem;
    }

    a {
      display: block;
      padding: 0.66rem;
      color: inherit;
      @media (max-width: 400px) {
        padding: 0.66rem 1.25rem;
        font-size: 1.25rem;
      }

      :focus {
        outline: none;
      }
    }
  }
`
const Landing = ({ pageContext }) => {
  const [activePack, setActivePack] = useState(null)
  const [agencySlider, setAgencySlider] = useState(null)
  const formRef = useRef(null)
  const { metaboxes, yoast_meta } = pageContext
  const {
    buttonText,
    buttonUrl,
    clientsSectionTitle,
    contactHeader,
    contactMail,
    contactMailPrefix,
    contactMobile,
    contactMobilePrefix,
    contactName,
    contactRole,
    contactSkype,
    contactFormTitle,
    contactImage,
    gainTitle,
    gainContent,
    title,
    text,
    servicesSectionTitle,
    portfolioSliderTitle,
    listSectionTitle,
    services,
    list,
    heroImage,
    gainImage,
    packSectionTitle,
    packs,
  } = metaboxes
  const handlePackClick = (packName) => {
    formRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
    trackCustomEvent({
      category: "Goals",
      action: `Landing - kliknięcie w sekcję pakietów "${packName}"`,
      label: "Landing - kliknięcie w sekcję pakietów",
    })
    setActivePack(packName)
  }
  useEffect(() => {
    window.location.href.indexOf("white-label") > -1
      ? setAgencySlider(true)
      : setAgencySlider(false)
  }, [])

  return (
    <Layout>
      <SEO
        meta={yoast_meta}
        title={pageContext.title}
        path={pageContext.path}
      />
      <Container style={{ marginTop: "20vh" }}>
        <FlexContainer>
          <SideBoxWrapper>
            <StyledSideBox>
              <h1 style={{ marginBottom: "0.75rem" }}>
                <BigMobileFont desktopFontSize="2.5rem">{title}</BigMobileFont>
              </h1>
              <HeroText dangerouslySetInnerHTML={{ __html: text }} />
              {buttonText && (
                <BaseButton
                  onClick={() => {
                    trackCustomEvent({
                      category: "Goals",
                      action: "Dowiedz się więcej - kliknięcie z LP",
                    })
                  }}
                  to={buttonUrl}
                >
                  {buttonText}
                </BaseButton>
              )}
            </StyledSideBox>
          </SideBoxWrapper>
          <EdgeImg localFile={heroImage.localFile} alt={heroImage.alt_text} />
        </FlexContainer>
        <ArrowMoreWrapper>
          <ArrowMore />
        </ArrowMoreWrapper>
        {clientsSectionTitle && (
          <div style={{ marginBottom: "80px" }}>
            <CenterBorderedHeader size="small">
              <BigMobileFont desktopFontSize="2.5rem">
                {clientsSectionTitle}
              </BigMobileFont>
            </CenterBorderedHeader>
            {agencySlider ? (
              <AgenciesSlider showMultipleClients />
            ) : (
              <ClientsSlider showMultipleClients />
            )}
          </div>
        )}
      </Container>
      <Container>
        <ServicesSection title={servicesSectionTitle} services={services} />

        <ButtonContainer>
          <BaseButton
            onClick={() => {
              trackCustomEvent({
                category: "Goals",
                action: "Bezpłatna konsulatacja - kliknięcie z LP",
              })
            }}
            to="/bezplatna-konsultacja"
          >
            Skorzystaj z bezpłatnej konsultacji
          </BaseButton>
        </ButtonContainer>

        <FlexContainer style={{ margin: "80px 0" }}>
          <ColumnImage localFile={gainImage.localFile} alt="" />
          <SidesBorderedBox side="right">
            <h2 style={{ marginBottom: "0.75rem" }}>
              <MediumMobileFont desktopFontSize="1.75rem">
                {gainTitle}
              </MediumMobileFont>
            </h2>
            <div
              style={{ fontFamily: "Hind", lineHeight: "1.625" }}
              dangerouslySetInnerHTML={{ __html: gainContent }}
            />
          </SidesBorderedBox>
        </FlexContainer>

        <CenterBorderedHeader>
          <BigMobileFont desktopFontSize="2.5rem">
            {portfolioSliderTitle}
          </BigMobileFont>
        </CenterBorderedHeader>
        <PortfolioSlider />
      </Container>

      <ListSection title={listSectionTitle} items={list} position="center" />

      {packs && packs.length > 0 && (
        <Container>
          <CenterBorderedHeader>
            <BigMobileFont desktopFontSize="2.5rem">
              {packSectionTitle}
            </BigMobileFont>
          </CenterBorderedHeader>
          <PackSection onPackSelect={handlePackClick} packs={packs} />
        </Container>
      )}

      <Container>
        <TwoColumns breakpoint="900px" style={{ marginTop: "96px" }}>
          <TwoColumns breakpoint="1195px" desktopMargin="0 32px 0 0">
            <BaseImg
              style={{ width: "280px", height: "280px", marginBottom: "0" }}
              localFile={contactImage.localFile}
              alt=""
            />
            <ContactInfo>
              <h2 style={{ marginBottom: "0.75rem" }}>
                <SmallMobileFont style={{ fontWeight: "700" }}>
                  {contactHeader}
                </SmallMobileFont>
              </h2>
              <p style={{ fontWeight: "700" }}>{contactName}</p>
              <p>{contactRole}</p>
              <p>
                {`${contactMailPrefix} `}
                <a style={{ fontWeight: "700" }} href={`mailto:${contactMail}`}>
                  {contactMail}
                </a>
              </p>
              <p>
                {`${contactMobilePrefix} `}
                <a style={{ fontWeight: "700" }} href={`tel:${contactMobile}`}>
                  {contactMobile}
                </a>
              </p>
            </ContactInfo>
          </TwoColumns>
          <ContactForm
            ref={formRef}
            packs={packs && packs.map((pack) => pack.title)}
            activePack={activePack}
            setActivePack={setActivePack}
            title={contactFormTitle}
          />
        </TwoColumns>
      </Container>
    </Layout>
  )
}

export default Landing
