import React, { useState } from "react"
import { Container, BorderedHeader } from "./styledComponents"
import CenterBorderedHeader from "./centerBorderedHeader"
import BorderedHeaderWrapper from "./borderedHeaderWrapper"
import ListItem from "./listItem"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { BigMobileFont } from "./typography"
import FlowMapImage from "./flowMapImage"

const Wrapper = styled.div`
  display: flex;
  margin: 4rem 0;

  @media (min-width: 1195px) {
    margin: ${(props) =>
      props.position === "center" ? "4rem 0" : "4rem 0 0 4rem"};
  }
`

const ImageWrapper = styled.div`
  width: ${(props) => (props.sectionPosition === "center" ? "17vw" : "22.5vw")};
  margin: ${(props) =>
    props.sectionPosition === "center" ? "10vw 0 0 0" : "0 0 -5vw 6vw"};
  @media (max-width: 1195px) {
    display: none;
  }
`

const ListContainer = styled(Container)`
  margin-right: ${(props) =>
    props.sectionPosition === "center" ? "auto" : "auto"};
  @media (max-width: 1195px) {
    width: 100%;
    margin: auto;
  }
`

const ListSection = ({
  title,
  items,
  position,
  headerPosition,
  headerSize,
}) => {
  const data = useStaticQuery(
    graphql`
      query {
        file(name: { eq: "sideWings" }) {
          publicURL
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  const [activeListItem, setActiveListItem] = useState(0)
  return (
    <Wrapper position={position}>
      {position === "center" ? (
        <ImageWrapper
          style={{ transform: "scaleX(-1)" }}
          sectionPosition={position}
        >
          <FlowMapImage
            flipMouse="x"
            imagePublicURL={data.file.publicURL}
            size={{ width: 260, height: 626 }}
            justify="end"
          />
        </ImageWrapper>
      ) : null}

      <ListContainer sectionPosition={position}>
        {position === "center" && headerPosition !== "left" ? (
          <CenterBorderedHeader style={{ marginBottom: "6.25rem" }}>
            <BigMobileFont desktopFontSize="2.5rem">{title}</BigMobileFont>
          </CenterBorderedHeader>
        ) : null}
        {position !== "center" || headerPosition === "left" ? (
          <BorderedHeaderWrapper size={headerSize}>
            <BorderedHeader whiteSpace="nowrap">
              <BigMobileFont desktopFontSize="2.5rem">{title}</BigMobileFont>
            </BorderedHeader>
          </BorderedHeaderWrapper>
        ) : null}
        <ol
          style={{
            margin: "4rem 0",
          }}
        >
          {items.map((item, index) => (
            <ListItem
              key={index}
              number={index + 1}
              content={item}
              active={activeListItem === index}
              setActive={() =>
                index !== activeListItem
                  ? setActiveListItem(index)
                  : setActiveListItem(-1)
              }
            />
          ))}
        </ol>
      </ListContainer>
      <ImageWrapper sectionPosition={position}>
        <FlowMapImage
          imagePublicURL={data.file.publicURL}
          size={{ width: 260, height: 626 }}
          justify="end"
        />
      </ImageWrapper>
    </Wrapper>
  )
}

export default ListSection
