import React from "react"
import styled from "styled-components"
import { MediumMobileFont } from "./typography"
import { BaseButton } from "./styledComponents"
import { useStaticQuery, graphql } from "gatsby"

const ColoredDiv = styled.div`
  flex: 33% 1 1;
  padding: 1.75rem 2rem;
  border: 2px solid ${props => props.color};
  box-shadow: 0 0 18px ${props => props.color};
  background-color: #fff;
  color: ${props => props.color};
  width: min(100%, 500px);
`

const StyledButton = styled(BaseButton)`
  margin-top: 1.25rem;
  padding: 0.75rem 2.75rem;
`

const StyledListItem = styled.li`
  position: relative;
  font-family: Hind;
  line-height: 1.125rem;
  margin-bottom: 0.75rem;

  ::after {
    content: '';
    position: absolute;
    left: -20px;
    top: 4px;
    width: 0.7rem;
    height: 0.7rem;
    background-image: url(${props => props.checkmarkUrl});
    background-size: contain;
    background-repeat: no-repeat;
  }

  ::before {
    content: '';
    position: absolute;
    left: -23px;
    top: 0;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 3px 6px #00000029;
  }
`

const StyledPacks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  align-items: start;
  justify-items: center;
  padding: 4.5rem 0;
  @media (max-width: 1195px){
    grid-template-columns: 1fr;
  }
`

const PackContentList = ({ list }) => {
  const { file } = useStaticQuery(
    graphql`
        query {
            file(name: { eq: "checkmark" }) {
                publicURL
            }
        }
    `,
  )
  return <ul style={{ listStyle: "none", marginBottom: "2rem" }}>{list.map((text) => {
    return <StyledListItem checkmarkUrl={file.publicURL} key={text}>{text}</StyledListItem>
  })}</ul>
}

const PackSection = ({ onPackSelect, packs }) => {
  const colors = ["#8A8A8A", "#21304A", "#E23E4A"]
  return <StyledPacks>
    {packs.map(({
                  buttonText,
                  price,
                  priceSuffix,
                  title,
                  list,
                }, index) => {
      return (
        <ColoredDiv color={colors[index]} key={title}>
          <h3>
            <MediumMobileFont desktopFontSize="1.5rem">{title}</MediumMobileFont>
          </h3>
          <PackContentList list={list} />
          <MediumMobileFont as="div" desktopFontSize="1.5rem">
            <strong>{price}</strong> {priceSuffix}
          </MediumMobileFont>
          <StyledButton as="button" type="button" onClick={() => onPackSelect(title)}>{buttonText}</StyledButton>
        </ColoredDiv>)
    })}</StyledPacks>
}
export default PackSection
