import React, { useState, useRef } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import ReCaptchaNotice from "./reCaptchaNotice"
import FormInput from "./formInput"
import styled from "styled-components"
import axios from "axios"
import { MediumMobileFont } from "./typography"
import { useStaticQuery, graphql } from "gatsby"
import LoadingSpinner from "./loadingSpinner"
import FormDetails from "./formDetails"

const CheckboxContainer = styled.div`
  position: relative;
  font-size: 0.875rem;
  line-height: 1.25rem;
  display: flex;
  margin-top: 10px;
  .custom-checkbox {
    display: inline-block;
    flex-shrink: 0;
    margin: 6px 6px 0 0;
    height: 9px;
    width: 9px;
    border: 1px solid ${(props) => props.theme.secondary};
  }
  input[type="checkbox"]:checked + label .custom-checkbox {
    background: ${(props) => props.theme.secondary};
  }
  a {
    text-decoration: underline;
  }
`

const SubmitButton = styled.input`
  display: inline-block;
  position: relative;
  white-space: nowrap;
  font-family: "Montserrat";
  border: none;
  color: #fff;
  font-weight: bold;
  background: ${(props) => props.theme.secondary};
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.secondary};
  transition: color 0.1s ease-in-out, background 0.2s ease-in-out;
  height: 40px;
  width: 100%;
  margin-top: 20px;
  :focus {
    border-style: dashed;
    background: #fff;
    color: ${(props) => props.theme.secondary};
    outline: none;
  }
  :hover {
    color: ${(props) => props.theme.secondary};
    background: #fff;
  }
`

const StyledCheckbox = styled.input`
  cursor: pointer;
  appearance: none;
  position: absolute;
  top: 6px;
  left: 0;
  width: 10px;
  height: 10px;
`

const StyledForm = styled.form`
  box-shadow: 0px 3px 6px #00000029;
  background: #fff;
  padding: 46px 70px;
  max-width: 580px;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding: 26px;
  }
`

const StyledTextarea = styled.textarea`
  padding: 13px 21px;
  height: 120px;
  box-shadow: 0px 3px 6px #00000029;
  border: none;
  width: 100%;
  resize: vertical;
  margin: 10px 0;
  :focus {
    outline: none;
  }
`

const ContactForm = ({ title, isUx }) => {
  const [sending, setSending] = useState(false)
  const [isSent, setIsSent] = useState(false)
  const [responseMessage, setResponseMessage] = useState({
    text: null,
    sent: null,
  })
  const [focused, setFocused] = useState(false)
  const recaptchaRef = useRef(null)
  const { site, wordpressPage } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          adminSiteUrl
        }
      }
      wordpressPage(
        template: { eq: "page-templates/page-global-settings.php" }
      ) {
        cmb2 {
          metabox_global_settings_contact_form {
            success
            submit
            phone
            name
            message
            error
            email
            checkboxes {
              content
              place
            }
            contactSummary
            contactDetails
          }
        }
      }
    }
  `)
  const {
    success,
    phone,
    name,
    submit,
    message,
    error,
    email,
    checkboxes,
    contactSummary,
    contactDetails,
  } = wordpressPage.cmb2.metabox_global_settings_contact_form
  const handleFormSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append("action", "send_mail")
    formData.append("pageTitle", document.title)
    formData.append("name", document.getElementById("form-name").value)
    formData.append("email", document.getElementById("form-email").value)
    formData.append("phone", document.getElementById("form-phone").value)
    formData.append("message", document.getElementById("form-message").value)
    setSending(true)

    setTimeout(() => {
      setResponseMessage({
        text: success,
        sent: true,
      })
      setSending(false)
      setIsSent(true)
      setFocused(false)
      document.getElementById("form-name").value = ""
      document.getElementById("form-email").value = ""
      document.getElementById("form-phone").value = ""
      document.getElementById("form-message").value = ""
    }, 1000)

    axios
      .post(
        `${site.siteMetadata.adminSiteUrl}/wp-admin/admin-post.php`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => response)
      .then(() => {
        console.log("E-mail sent")
      })
      .catch((error) => {
        setResponseMessage({
          text: error,
          sent: false,
        })
        throw new Error(`Response returned status code: ${error}`)
      })
  }

  return (
    <StyledForm onSubmit={(e) => handleFormSubmit(e)}>
      <h2 style={{ textAlign: "center", marginBottom: "0.75rem" }}>
        <MediumMobileFont desktopFontSize="1.5rem">{title}</MediumMobileFont>
      </h2>
      <FormInput
        id="form-name"
        type="text"
        label={`${name}*`}
        isSent={isSent}
        required
      />
      <FormInput
        id="form-email"
        type="email"
        label={`${email}*`}
        isSent={isSent}
        required
      />
      <FormInput
        id="form-phone"
        type="tel"
        label={phone}
        isSent={isSent}
        pattern="\+?(\d|\s)*"
      />

      <div style={{ position: "relative" }}>
        <label
          style={{
            position: "absolute",
            top: "20px",
            left: "21px",
            transition: "transform 0.3s ease-in-out",
            transformOrigin: "left",
            transform: focused ? "scale(0.6) translate(-35px, -50px)" : "",
          }}
          htmlFor="form-message"
        >
          {message}
        </label>
        {isUx ? (
          <FormInput
            id="form-message"
            type="url"
            label="Adres witryny sklepu internetowego*"
            isSent={isSent}
            required
          />
        ) : (
          <StyledTextarea
            onBlur={(e) => (e.target.value === "" ? setFocused(false) : null)}
            onFocus={() => setFocused(true)}
            id="form-message"
          />
        )}
      </div>

      {checkboxes.map(
        (checkbox, index) =>
          checkbox.place.includes("contact/free-project-estimation") && (
            <CheckboxContainer key={index}>
              <StyledCheckbox
                id={`form-acceptance-${index}`}
                type="checkbox"
                required
              />
              <label
                htmlFor={`form-acceptance-${index}`}
                style={{ cursor: "pointer" }}
              >
                <div className="custom-checkbox" />
                <span
                  dangerouslySetInnerHTML={{ __html: checkbox.content }}
                ></span>
              </label>
            </CheckboxContainer>
          )
      )}
      <FormDetails summary={contactSummary} details={contactDetails} />
      <ReCaptchaNotice />

      <SubmitButton
        type="submit"
        value={isUx ? "zamów bezpłatny audyt" : submit}
      />
      {sending ? <LoadingSpinner /> : null}
      {responseMessage.text === null ? null : (
        <div
          style={{
            marginTop: "2rem",
            textAlign: "center",
            fontWeight: "bold",
            color: `${responseMessage.sent === true ? "green" : "red"}`,
          }}
        >
          {responseMessage.text}
        </div>
      )}
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="Your client site key"
      />
    </StyledForm>
  )
}
export default ContactForm
