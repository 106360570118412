import React from "react"
import styled, { css } from "styled-components"
import { useAnimatedBorder } from "../helpers"

const BorderedBox = styled.div`
  flex: 48% 0 0;
  margin-${(props) => (props.side === "left" ? "right" : "left")}: 5%;
  padding: 3rem 2rem;
  position: relative;
  border: 1px solid ${(props) => props.theme.secondary};
  border-top: none;
  border-bottom: none;
  @media (max-width: 768px) {
    margin: 50px 0 0;
    order: ${(props) => (props.side === "left" ? "1" : "")}
  }
`

const Border = styled.div`
  position: absolute;
  top: 0;
  z-index: -1;
  height: 100%;
  width: 33%;
  left: 0;

  ${(props) =>
    props.side === "right" &&
    css`
      left: auto;
      right: 0;
    `}
  ::before,
  ::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    border-top: 1px solid ${(props) => props.theme.secondary};
    ${(props) =>
      props.side === "right" &&
      css`
        left: auto;
        right: 0;
        border-top: none;
        border-bottom: 1px solid ${(props) => props.theme.secondary};
      `}
    width: 100%;
    transition: transform 1s ease-out;
  }

  ::before {
    top: 0;
    transform: scaleX(calc(1 + var(--scale)));
    transform-origin: left;
    ${(props) =>
      props.side === "right" &&
      css`
        transform: scaleX(calc(1 - var(--scale)));
        transform-origin: right;
      `}
  }

  ::after {
    bottom: 0;
    transform: scaleX(calc(1 - var(--scale)));
    transform-origin: left;
    ${(props) =>
      props.side === "right" &&
      css`
        transform: scaleX(calc(1 + var(--scale)));
        transform-origin: right;
      `}
  }
`

const SidesBorderedBox = ({ side, children }) => {
  const [boxRef] = useAnimatedBorder()

  return (
    <BorderedBox side={side} ref={boxRef}>
      <Border side="left" />
      {children}
      <Border side="right" />
    </BorderedBox>
  )
}

export default SidesBorderedBox
