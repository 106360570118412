import React from "react"
import { BaseButton } from "./styledComponents"
import styled from "styled-components"
import { MediumMobileFont } from "./typography"

const ContentHeader = styled.h3`
  margin-bottom: 0;
  color: ${(props) =>
    props.active ? props.theme.secondary : props.theme.primary};
`

const MoreButton = styled.button`
  background: none;
  border: none;
  margin-left: auto;
  font-size: 56px;
  font-weight: 600;
  user-select: none;
  color: ${(props) =>
    props.active ? props.theme.primary : props.theme.secondary};
  transition: transform 0.3s ease, color 0.2s ease;
  transform: ${(props) => (props.active ? "rotate(45deg)" : "")};
  outline: none;
  cursor: pointer;
  :focus,
  :hover {
    color: ${(props) => props.theme.primary};
  }
`

const ListEntry = styled.li`
  width: 800px;
  display: block;
  padding: 1.5rem 0;
  margin: 0;
  border-top: 1px solid ${(props) => props.theme.lightGray};
  :last-child {
    border-bottom: 1px solid ${(props) => props.theme.lightGray};
  }
  @media screen and (max-width: 1195px) {
    width: auto;
  }
`

const Count = styled.div`
  font-size: 1.125rem;
  font-weight: 300;
  color: #707070;
  width: 60px;
  color: ${(props) => (props.active ? props.theme.secondary : "#707070")};
  flex-shrink: 0;
`

const Content = styled.div`
  transform-origin: top;
  transform: translateY(${(props) => (props.active ? "0" : "-20px")});
  opacity: ${(props) => (props.active ? "1" : "0%")};
  margin-top: ${(props) => (props.active ? "1rem" : "0")};
  height: ${(props) => (props.active ? "auto" : "0")};
  pointer-events: ${(props) => (props.active ? "all" : "none")};
  transition: ${(props) =>
    props.active ? "transform 0.2s ease-out, opacity 0.3s ease-out;" : "none"};
  display: block;
  margin-left: 60px;
  @media screen and (max-width: 1195px) {
    margin-left: 0;
  }
`

const ListEntryHead = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  background: none;
  border: none;
  :focus {
    outline: none;
  }
`

const ListItem = ({ number, content, active, setActive }) => {
  const { title, contentText, contentTitle, buttonText, buttonUrl } = content

  return (
    <ListEntry>
      <ListEntryHead tabIndex="-1" onClick={() => setActive()}>
        <Count active={active}>
          {number.length === 2 ? number : "0" + number}
        </Count>
        <ContentHeader active={active}>
          <MediumMobileFont>{title}</MediumMobileFont>
        </ContentHeader>
        <MoreButton aria-expanded={active} type="button" active={active}>
          +
        </MoreButton>
      </ListEntryHead>
      <Content active={active}>
        <p style={{ marginBottom: "0.75rem" }}>
          <b>{contentTitle}</b>
        </p>
        <p dangerouslySetInnerHTML={{ __html: contentText }}></p>
        {buttonText && <BaseButton to={buttonUrl}>{buttonText}</BaseButton>}
      </Content>
    </ListEntry>
  )
}

export default ListItem
