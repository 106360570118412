import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { useSwiper } from "../hooks/useSwiper"
import { MediumMobileFont } from "../typography"
import SliderControls from "./sliderControls"
import BaseImg from "../baseImg"

const Slide = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`

const Slider = styled.div`
  width: 100%;
  display: flex;
`

const SlideContent = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  @media (max-width: 991px) {
    flex-direction: column-reverse;
  }
`

const SliderImg = styled(BaseImg)`
  position: relative;
  z-index: -1;
  max-width: 50vw;
  max-height: 45rem;
  margin: -5% 0 -5% -150px;
  @media (max-width: 991px) {
    width: auto;
    margin-left: 0;
  }
`

const PortfolioSlider = () => {
  const { wordpressPage, allWordpressWpProject } = useStaticQuery(graphql`
    query {
      wordpressPage(
        template: { eq: "page-templates/page-global-settings.php" }
      ) {
        cmb2 {
          metabox_global_settings_templates {
            portfolioBusinessTitle
            portfolioVisitTitle
            portfolioServicesTitle
          }
        }
      }
      allWordpressWpProject {
        nodes {
          title
          cmb2 {
            metabox_post_project {
              business
              description
              sliderImage {
                localFile {
                  publicURL
                }
              }
              projectDescriptionContent
              services
              solutionsContent
              visit_url
            }
          }
        }
      }
    }
  `)
  const [projects, setProjects] = useState(allWordpressWpProject.nodes)
  useEffect(() => {
    setProjects(
      allWordpressWpProject.nodes.filter(
        (project) => project.cmb2.metabox_post_project.sliderImage
      )
    )
  }, [allWordpressWpProject.nodes])
  const { swiperElement, swiperInstance, activeSlideIndex } = useSwiper(
    projects
  )

  const {
    portfolioBusinessTitle,
    portfolioServicesTitle,
    portfolioVisitTitle,
  } = wordpressPage.cmb2.metabox_global_settings_templates
  return (
    <div>
      <Slider>
        <div className="swiper-container" ref={swiperElement}>
          <ul className="swiper-wrapper">
            {projects.map((project) => {
              const {
                business,
                description,
                sliderImage,
                services,
                visit_url,
              } = project.cmb2.metabox_post_project
              return (
                <Slide className="swiper-slide" key={project.title}>
                  <SlideContent>
                    <div
                      style={{
                        lineHeight: "1.625",
                        background: "#fff",
                        boxShadow: "0px 3px 6px #00000029",
                        padding: "28px 36px",
                        maxWidth: "478px",
                        position: "relative",
                      }}
                    >
                      <h3 style={{ marginBottom: "0.75rem" }}>
                        <MediumMobileFont>{project.title}</MediumMobileFont>
                      </h3>
                      <p
                        dangerouslySetInnerHTML={{ __html: description }}
                        style={{ marginBottom: "0.5rem" }}
                      />
                      <p style={{ marginBottom: "0.5rem" }}>
                        <span style={{ fontWeight: "700" }}>
                          {portfolioServicesTitle}:
                        </span>{" "}
                        {services
                          ? services.map((service, index) =>
                              index === services.length - 1
                                ? service
                                : service + " | "
                            )
                          : null}
                      </p>
                      <p style={{ marginBottom: "0.5rem" }}>
                        <span style={{ fontWeight: "700" }}>
                          {portfolioBusinessTitle}:
                        </span>{" "}
                        {business
                          ? business.map((singleBusiness, index) =>
                              index === business.length - 1
                                ? singleBusiness
                                : singleBusiness + " | "
                            )
                          : null}
                      </p>
                      <p style={{ marginBottom: "0" }}>
                        <span style={{ fontWeight: "700" }}>
                          {portfolioVisitTitle}:{" "}
                        </span>
                        <a
                          href={visit_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {visit_url}
                        </a>
                      </p>
                    </div>

                    <SliderImg
                      alt=""
                      localFile={sliderImage ? sliderImage.localFile : ""}
                    />
                  </SlideContent>
                </Slide>
              )
            })}
          </ul>
        </div>
      </Slider>
      <SliderControls
        position="bottom-right"
        swiperInstance={swiperInstance}
        activeSlideIndex={activeSlideIndex}
        slides={projects}
      />
    </div>
  )
}

export default PortfolioSlider
